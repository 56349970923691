export enum FontSizes {
  default = '16px',
  h1 = '36px',
  h2 = '28px',
  h3 = '24px',
  h4 = '20px',
  h5 = '14px',
}

export enum Font {
  default = "font: 16px -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
  bold = "font-weight: bold",
  logo = "font: 16px 'Pacifico', cursive;",
  hero = "font: 64px 'Lobster', cursive;",
}