import React from 'react'
import {
  Route,
  Routes,
} from "react-router-dom"

import { Footer } from 'components/footer'
import { Navbar } from 'components/navbar'

import GlobalStyles from './GlobalStyles'
import Home from './home'

const App = () => (
  <>
    <GlobalStyles />
    <Navbar />
    <Routes>
      <Route
        path="/"
        element={<Home />}
      />
      <Route
        path="login"
        element={<div>log in</div>}
      />
      <Route
        path="signup"
        element={<div>sign up</div>}
      />
    </Routes>
    <Footer />
  </>
)

export default App;
