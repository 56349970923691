const Color = {
  black: '#000000',
  cta: '#007fff',
  grays: {
    gray10: '#d4d4d4',
    gray20: '#989898',
    gray30: '#878787',
    gray40: '#767676',
    gray50: '#656565',
    gray60: '#555555',
    gray70: '#444444',
    gray80: '#333333',
    gray90: '#222222',
    gray100: '#1a1a1a',
  },
  white: '#ffffff'
} as const

export default Color