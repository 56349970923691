import React from 'react'
import styled from 'styled-components'

import { Font, FontSizes } from 'components/font'

const StyledSpan = styled.span`
  ${({ isBold }: { isBold: boolean }) => isBold ? Font.bold : ''}
  font-size: ${FontSizes.default};
`

const Text = ({
  children,
  isBold = false,
} : {
  children: React.ReactNode,
  isBold?: boolean
}) => (
  <StyledSpan isBold={isBold}>{children}</StyledSpan>
)

export default Text
