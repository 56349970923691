import React from 'react'
import styled from 'styled-components'

import { Color } from 'components/color'
import { Font } from 'components/font'
import { Heading } from 'components/text'

const StyledLogo = styled.span`
  ${Font.logo}
  color: ${Color.cta};
  display: inline-block;
  padding: 0 16px 0 16px;
`

const Logo = () => (
  <StyledLogo><Heading variant="1">con-db</Heading></StyledLogo>
)

export default Logo