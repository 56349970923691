import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import styled from 'styled-components'

import { Color } from 'components/color'
import { Link } from 'components/link'
import { Logo } from 'components/logo'

const StyledNavBar = styled.div`
  align-items: center;
  background-color: ${Color.grays.gray100};
  display: flex;
  justify-content: space-between;
  padding: 8px 64px 8px 64px;
`

const Links = styled.div`
  color: ${Color.white};

  & > * {
    padding: 0 16px 0 16px;
  }
`

const Navbar = () => (
  <StyledNavBar>
    <ReactRouterLink to="/"><Logo /></ReactRouterLink>
    <Links>
      <Link to="http://localhost:6006/">Styleguide</Link>
      <Link to="/login">Log in</Link>
      <Link to="/signup">Sign up</Link>
    </Links>
  </StyledNavBar>
)

export default Navbar
