import React from 'react'
import styled from 'styled-components'

import { Link as ReactRouterLink, LinkProps } from 'react-router-dom'

const StyledLink = styled(ReactRouterLink)`
  color: inherit;
  text-decoration: none;
`

const Link = (props: LinkProps) => (
  <StyledLink {...props} />
)

export default Link
