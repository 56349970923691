import { FontSizes } from 'components/font'

export const Headings = {
  '1': FontSizes.h1,
  '2': FontSizes.h2,
  '3': FontSizes.h3,
  '4': FontSizes.h4,
  '5': FontSizes.h5
} as const

export type HeadingType = keyof typeof Headings
