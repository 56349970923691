import React from 'react'
import styled from 'styled-components'

import { Color } from 'components/color'
import { Link } from 'components/link'
import { Text } from 'components/text'

const StyledFooter = styled.div`
  background-color: ${Color.grays.gray100};
  margin-top: 64px;
  padding: 32px 0 32px 0;
`

const StyledLinks = styled.div`
  color: ${Color.grays.gray10};
  margin: 0 auto;
  justify-content: space-around;
  width: 1080px;
`

const StyledDivider = styled.span`
  padding: 0 8px 0 8px;
  &:before {
    content: "·";
  }
`

const Footer = () => (
  <StyledFooter>
    <StyledLinks>
      <Text isBold>© 2023 con-db</Text>
      <StyledDivider />
      <Link to="/terms"><Text>Terms of Service</Text></Link>
      <StyledDivider />
      <Link to="/privacy"><Text>Privary Policy</Text></Link>
      <StyledDivider />
      <Link to="/sitempa"><Text>Sitemap</Text></Link>
      <StyledDivider />
      <Link to="/about"><Text>About</Text></Link>
      <StyledDivider />
      <Link to="/careers"><Text>Careers</Text></Link>
    </StyledLinks>
  </StyledFooter>
)

export default Footer
