import { createGlobalStyle } from "styled-components";
import { Normalize } from 'styled-normalize'

import { Font } from 'components/font'

const GlobalStyle = createGlobalStyle`
  body {
    ${Font.default}
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`

const GlobalStyles = () => (
  <>
    <Normalize />
    <GlobalStyle />
  </>
)

export default GlobalStyles
