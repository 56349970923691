import React from 'react'
import styled from 'styled-components'

import { Headings, HeadingType } from './constants'

const StyledDiv = styled.div`
  font-size: ${({ variant }: { variant: HeadingType }) => Headings[variant]};
`

const Heading = ({
  children,
  variant,
} : {
  children: React.ReactNode,
  variant: HeadingType,
}) => (
  <StyledDiv variant={variant}>{children}</StyledDiv>
)

export default Heading
